<template>
  <div class="mr-4 flex items-center xl:text-xl">
    <font-awesome-icon :icon="['fas', 'star']" class="mr-2 text-yellow" />
    <span class="whitespace-nowrap">{{ text }}</span>
  </div>
</template>
<script>
export default {
  props: {
    text: { type: String, default: '標題' },
  },
};
</script>
<style lang=""></style>

<template>
  <div class="my-12 container">
    <Title text="會員方案紀錄"></Title>
    <div class="bg-white rounded shadow p-4 mt-8">
      <ElTable :data="rows" empty-text="目前沒有資料" :row-class-name="status">
        <el-table-column prop="date" label="購買日期" sortable />
        <el-table-column prop="title" label="方案名稱" sortable />
        <el-table-column prop="subject" label="科目" sortable />

        <el-table-column prop="dayCnt" width="100" label="天數" sortable />
        <el-table-column prop="period" width="300" label="會員期限" sortable />
      </ElTable>
    </div>
  </div>
</template>

<script setup>
import Title from '@/components/Titles.vue';

import { ElTable, ElTableColumn } from 'element-plus';

import { ref } from 'vue';
import Payment from '@/api/Payment';
const rows = ref([]);

const status = ({ row, column }) => {
  return row.expired == 1 ? 'opacity-25' : '';
};
const fetcher = () => {
  Payment.historyMembership().then((res) => {
    if (res.code === 1) {
      rows.value = res.data.rows;
    }
  });
};
fetcher();
</script>

<style lang="sass" scoped>
:deep(.el-table)
  @screen md
    colgroup
      display: none
    thead
      display: none
    tr
      border-bottom: 1px solid #eee

    td
      display: block

      &:not(:last-child)
        border-bottom: 0
</style>
